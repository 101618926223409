import { useEffect, useState } from 'react';

export type Episode = {
  episode_id: string,
  competition_id: string,
  first_shown: string;
  length: number;
  city: string;
  part: number;
  total_parts: number;
  description: string;
  link: string;
  watched: boolean;
};

export function useEpisodeData() {
  const [data, setData] = useState<Episode[]>();
  const [filteredData, setFilteredData] = useState<Episode[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [onlyOmnibus, setOnlyOmnibus] = useState<boolean>(false);

  const [watchedList, setWatchedList] = useState<string[]>(() => {
    const storedData = localStorage.getItem('watchedList');
    return storedData ? JSON.parse(storedData) : [];
  });

  useEffect(() => {
    localStorage.setItem('watchedList', JSON.stringify(watchedList));
  }, [watchedList]);

  const toggleWatched = (id: string): void => {
    setWatchedList(currentList => {
      if (currentList.includes(id)) {
        return currentList.filter(item => item !== id);
      } else {
        return [...currentList, id];
      }
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch('/data.json');  // Hardcoded path to the public directory file
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const json = await response.json() as Episode[];
        json.sort((x, y) => 
          x.first_shown < y.first_shown ? -1 : 
          x.first_shown > y.first_shown ? 1 : 
          x.part < y.part ? -1 : 
          x.part > y.part ? 1 : 0
        )
        const initializedData = json.map(item => ({
          ...item,
          watched: watchedList.includes(item.episode_id)
        }));
        setData(initializedData);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const updatedData = data?.map(item => ({
      ...item,
      watched: watchedList.includes(item.episode_id)
    }));
    setData(updatedData)
  }, [watchedList])

  useEffect(() => {
    const filtered = onlyOmnibus ? data?.filter(e => e.total_parts == 1) : data
    filtered && setFilteredData(filtered)
  }, [data, filteredData, onlyOmnibus])

  const handleWatchedClick = (id: string) => {
    toggleWatched(id)
  };  

  const toggleOnlyOmnibus = () => {
    setOnlyOmnibus(!onlyOmnibus)
  }

  return {
    filteredData,
    isLoading,
    error,
    onlyOmnibus,
    toggleOnlyOmnibus,
    handleWatchedClick,
  };
}
