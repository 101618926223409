import React, { useState } from 'react';
import './MobileWarning.css';

interface MobileWarningProps {
    onClose: () => void;
}

const MobileWarning = ({ onClose }: MobileWarningProps) => {
    return (
        <div className="browser-warning">
            <div className="browser-warning-content">
                <p>This website works better on a browser or if you don't have the Channel 4 app installed.</p>
                <p>If you have the Channel 4 app installed, links open the app to the Come Dine With Me homepage instead of a specific episode.</p>
                <button className="close-button" onClick={onClose}>x</button>
            </div>
        </div>
    );
}

export default MobileWarning;
