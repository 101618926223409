import './Loading.css'


function Loading(){
    return (
        <div className="loading">
        <p>Loading...</p>
        </div>
    );
  }

  export default Loading;