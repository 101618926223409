import './Title.css'

type TitleProps = {
    toggleOnlyOmnibus: () => void;
    onlyOmnibus: boolean
}
function Title({toggleOnlyOmnibus, onlyOmnibus}: TitleProps) {
    return (
        <div className="title">
        <h1>the come dine with me index</h1>
        <p onClick={toggleOnlyOmnibus}>{`${onlyOmnibus ? '■' : '□'} only show omnibus episodes`}</p>
        </div>
    );
  }

  export default Title;