import React from 'react';
import { Episode } from './useEpisodeData';
import './TableData.css'
import { isMobile, isBrowser } from 'react-device-detect';

const TableData: React.FC<any> = ({ data, onWatchedClick }) => {

  // Change to material-react-table: https://www.material-react-table.com/docs/examples/infinite-scrolling
  
  const onRowClick = (i: Episode) => {
    !i.watched && onWatchedClick(i.episode_id);
    console.log(i)
    window.open(`https://www.channel4.com/programmes/come-dine-with-me/on-demand/${i.link}`, '_blank');
  }

  return (
  <table className={isMobile ? 'mobile-table' : 'browser-table' }>
    <thead>
    <tr>
      <th scope="col"></th>
      <th scope="col">Aired</th>
      <th scope="col">Length</th>
      <th scope="col">City</th>
      <th scope="col">Part</th>
      {isBrowser && <th scope="col">Description</th>}
    </tr>
  </thead>
  <tbody>
    {data.map((i: Episode) => (
      <tr className={i.watched ? "watched": ""} key={i.link} onClick={() => onRowClick(i)}>
        <td scope="row" onClick={(e) =>{e.stopPropagation(); onWatchedClick(i.episode_id)}} className="watched-button">{i.watched ? "■"  : "□"}</td>
        <td scope="row">{i.first_shown}</td>
        <td scope="row">{`${i.length} min`}</td>
        <td scope="row" className="city">{i.city}</td>
        <td scope="row">{i.part} of {i.total_parts}</td>
        {isBrowser && <td scope="row">{i.description}</td>}
      </tr>
    ))}
   
  </tbody>
  </table>
);
};

export default TableData;