import './App.css';

import { useState } from 'react';
import TableData from './TableData';
import Title from './Title';
import MobileWarning from './MobileWarning';
import {useEpisodeData} from './useEpisodeData';
import Loading from './Loading';
import ErrorPage from './ErrorPage';
import { isMobile } from 'react-device-detect';


const App = () => {
  const { filteredData, isLoading, error, toggleOnlyOmnibus, onlyOmnibus, handleWatchedClick } = useEpisodeData();
  const [showWarning, setShowWarning] = useState(isMobile);

  if (isLoading) return <Loading/>;
  if (error) return <ErrorPage/>;
  return <>
    {showWarning && <MobileWarning onClose={() => setShowWarning(false)}/>}
    <Title toggleOnlyOmnibus={toggleOnlyOmnibus} onlyOmnibus={onlyOmnibus} />
    <TableData data={filteredData} onWatchedClick={handleWatchedClick}></TableData>;
  </>
};

export default App;