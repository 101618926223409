import './ErrorPage.css'


function ErrorPage(){
    return (
        <div className="error">
        <p>We have experienced an error<br/><br/><br/>..apologies</p>
        </div>
    );
  }

  export default ErrorPage;